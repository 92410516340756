import { requestCSCampaign, requestCampaignHistory, requestOngoingCampaigns } from 'apis/cs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  NoAdModalState,
  csState,
  customState,
  isNewInAppBrowserState,
  isTestState,
  mediaKeyState,
  parameterState,
  platformState,
} from 'recoil/atoms/campaignState';

import { Modal } from '../../components/modal';

import { ReactComponent as Check } from 'assets/check.svg';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';
import arrowBack from '../../assets/arrow_back.png';
import arrowDropDown from '../../assets/arrow_drop_down.png';

import expandMore from '../../assets/expand_more.png';
import { useErrorModal } from '../../context';

export const CSPage = () => {
  function parseQueryString(query) {
    const params = {};
    // 쿼리 파라미터 추가
    query.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }

  const { openModal, modalContent } = useErrorModal();
  const [csData, setCSData] = useRecoilState(csState);
  const [isTest, setIsTest] = useRecoilState(isTestState);

  const parameter = useRecoilValue(parameterState);
  const mediaKey = useRecoilValue(mediaKeyState);
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const isTestFromQS = queryParams.get('isTest');
  const appKeyFromQS = queryParams.get('app_key');
  const usnFromQS = queryParams.get('usn');
  const adidFromQS = queryParams.get('adid');
  const idfaFromQS = queryParams.get('idfa');

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const ISTEST = isTestFromQS ? isTestFromQS === 'true' : isTest;
    const MEDIAKEY = appKeyFromQS || mediaKey || '';
    const USN = usnFromQS || parameter?.usn || '';
    const ADID = adidFromQS || parameter?.adid || '';
    const IDFA = idfaFromQS || parameter?.idfa || '';
    const PARAMS = isTestFromQS ? parseQueryString(queryParams) : parameter;

    const csParameter = {
      app_key: MEDIAKEY,
      usn: USN,
      adid: ADID,
      idfa: IDFA,
    };

    async function fetchCSCampaign() {
      try {
        const data = await requestCSCampaign(csParameter, ISTEST);
        setCSData((prevData) => ({ ...prevData, csCampaign: data }));
      } catch (err) {
        if (modalContent) return;
        setCSData((prevData) => ({ ...prevData, csCampaign: [] }));
        openModal(err.message || '', handleGoBack);
      }
    }
    async function fetchCampaignHistory() {
      try {
        const data = await requestCampaignHistory(csParameter, ISTEST);
        setCSData((prevData) => ({ ...prevData, csHistory: data }));
      } catch (err) {
        if (modalContent) return;
        setCSData((prevData) => ({ ...prevData, csHistory: {} }));
        openModal(err.message || '', handleGoBack);
      }
    }

    async function fetchOngoingCampaigns() {
      try {
        const data = await requestOngoingCampaigns({ app_id: MEDIAKEY, ...PARAMS }, ISTEST);
        setCSData((prevData) => ({ ...prevData, ongoingCampaigns: data }));
      } catch (err) {
        if (modalContent) return;
        setCSData((prevData) => ({ ...prevData, ongoingCampaigns: [] }));
        openModal(err.message || '', handleGoBack);
      }
    }

    if (!PARAMS) return;
    if (!MEDIAKEY) return;

    // const { csHistory, csCampaign, ongoingCampaigns } = csData;
    // const hasCSData =
    //   Object.keys(csHistory).length > 0 || csCampaign?.length > 0 || ongoingCampaigns?.length > 0;
    // if (hasCSData) return;
    fetchCSCampaign();
    fetchCampaignHistory();
    fetchOngoingCampaigns();
  }, []);

  const [showNoAdModal, setShowNodAdModal] = useRecoilState(NoAdModalState);
  const [selectedTab, setSelectedTab] = useState(showNoAdModal ? 1 : 0);

  const tabList = ['적립내역', '자주 묻는 질문', '문의하기'];

  const { csHistory, csCampaign, ongoingCampaigns } = csData;

  const ADID = adidFromQS || parameter?.adid || '';

  return (
    <>
      <div className="w-full h-[100vh] bg-white z-10 flex flex-col absolute top-0 overflow-hidden left-0">
        <div
          className="h-[44px] flex gap-[6px] px-[15px] py-[10px] justify-left items-center w-fit"
          role="presentation"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <img width={24} height={24} src={arrowBack} alt="뒤로가기" />
          <div className="text-[18px] leading-[25.2px] text-gray-900">고객센터</div>
        </div>
        <div className="px-[15px] grid grid-cols-3 gap-[22px] text-[16px] leading-[22.4px] border-b border-gray-100 font-400">
          {tabList.map((tab, idx) => (
            <div
              key={idx}
              role="presentation"
              onClick={() => {
                setSelectedTab(idx);
              }}
              className={`py-[10px] flex items-center justify-center ${
                idx === selectedTab
                  ? 'text-gray-900 font-bold border-b-[2px] border-gray-900'
                  : 'text-gray-500'
              }`}
            >
              {tab}
            </div>
          ))}
        </div>
        <div
          className={`flex flex-col bg-white  h-full ${
            selectedTab === 0 ? 'overflow-hidden' : 'overflow-scroll'
          }`}
        >
          {selectedTab === 0 && (
            <CSHistory
              completedCampaigns={csHistory?.CompletedCampaigns || []}
              ongoingCampaigns={ongoingCampaigns}
            />
          )}
          {selectedTab === 1 && <CSFAQ faqList={csHistory?.FaqList || []} />}
          {selectedTab === 2 && (
            <CSCampaigns
              csCampaign={csCampaign}
              disabled={ADID === '00000000-0000-0000-0000-000000000000'}
            />
          )}
        </div>
      </div>
    </>
  );
};

function CSHistory({ completedCampaigns, ongoingCampaigns }) {
  const [campaignStatus, setCampaignStatus] = useState('ONGOING');
  const custom = useRecoilValue(customState);

  const campaignStatusList = [
    { name: '참여중', value: 'ONGOING' },
    { name: '참여완료', value: 'COMPLETED' },
  ];
  return (
    <div className="flex flex-col gap-[20px] h-full">
      <div className="flex gap-[10px] text-[14px] tracking-[-0.5px] py-[12px] px-[16px] font-400 whitespace-nowrap w-full overflow-x-auto h-[50px]">
        {campaignStatusList?.map((campaign) => (
          <div
            key={campaign.value}
            role="presentation"
            onClick={() => {
              setCampaignStatus(campaign.value);
            }}
            className={`py-[4px] px-[10px] rounded-[6px] flex justify-center items-center ${
              campaign.value === campaignStatus
                ? 'text-white bg-gray-900'
                : 'text-gray-500 bg-[#F8F9FA]'
            }`}
          >
            {campaign.name}
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-[20px] px-[16px] bg-white h-full overflow-scroll">
        {campaignStatus === 'COMPLETED' &&
          (completedCampaigns?.length ? (
            completedCampaigns?.map((campaign, idx) => (
              <div className="flex flex-col gap-[20px]" key={idx}>
                <div className="flex justify-between">
                  <div className="flex flex-col gap-[2px]">
                    <div className="font-[500] text-[15px] text-gray-900 h-[20px]">
                      {campaign.Title}
                    </div>
                    <div className="font-[400] h-[18px] leading-[17.94px] text-[13px] text-gray-500">
                      {campaign.Date}
                    </div>
                  </div>
                  <div
                    className="font-[600] text-[15px] leading-[20.4px]"
                    style={{
                      color: custom.primaryColor,
                    }}
                  >
                    +{campaign.Reward}
                  </div>
                </div>
                <div className="w-full h-[1px] bg-[#F9FAFA]" />
              </div>
            ))
          ) : (
            <div className="w-full h-full flex justify-center items-center text-gray-500 text-[14px]">
              <span>지급 내역이 없습니다.</span>
            </div>
          ))}
        {campaignStatus === 'ONGOING' &&
          (ongoingCampaigns?.MultiRewardCampaigns?.length ? (
            ongoingCampaigns?.MultiRewardCampaigns?.map((campaign, idx) => (
              <div
                className="flex gap-[12px] text-[15px] text-[#212529] pb-[20px] border-b border-[#F1F2F4]"
                key={idx}
              >
                <img
                  src={campaign.IconImgURL}
                  className="w-[64px] h-[64px] rounded-[8px]"
                  alt={campaign.Title}
                />
                <div>{campaign.Title}</div>
              </div>
            ))
          ) : (
            <div className="w-full h-full flex justify-center items-center text-gray-500 text-[14px]">
              <span>참여중인 캠페인이 없습니다.</span>
            </div>
          ))}
      </div>
    </div>
  );
}

function CSFAQ({ faqList }) {
  const platform = useRecoilValue(platformState);
  return (
    <>
      {faqList?.map((campaign) => (
        <div className="text-gray-900" key={campaign.Title}>
          {/* <a
            href={campaign.Link || ''}
            onClick={(e) => openLinkInNewTab(e, campaign?.Link || '')}
            rel="noopener noreferrer"
          > */}
          <div
            className="text-[16px] px-[15px] py-[22px] leading-[24px] flex justify-between"
            role="presentation"
            onClick={(e) => {
              // window?.APRewardOfferwall?.openWebBrowser?.(campaign.Link || '');
              sendMessageToSDK('openWebBrowser', { url: campaign.Link || '' }, platform);
            }}
          >
            {campaign.Title}

            <img width={20} height={20} src={expandMore} alt="더보기" />
          </div>
          {/* </a> */}
          <div className="w-full h-[1px] bg-gray-100" />
        </div>
      ))}
    </>
  );
}

const EtcQuestions = () => {
  const [option, setOption] = useState('1');
  const [showAlert, setShowAlert] = useState(false);
  const [showIosAlert, setShowIosAlert] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const platform = useRecoilValue(platformState);
  const custom = useRecoilValue(customState);
  const parameter = useRecoilValue(parameterState);
  const isNewInappBrowser = useRecoilValue(isNewInAppBrowserState);
  const placeholderMsgObject = {
    1: '해당 앱 고객센터로 문의해 주세요',
    2: '참여 이력에서 해당 광고를 참여했는지 다시 확인 부탁 드립니다. 참여 이력에도 없다면, 참여 대상이 아닌 광고이니 다른 광고를 참여해 주세요',
    3: '개인정보 수집을 해제하면 더 이상 애드팝콘\n 서비스를 사용하실 수 없습니다.',
  };

  const onClickDisAgree = () => {
    setShowAlert(false);
    setShowAlert2(true);
    // if (!window) return;
    // if (!window?.APRewardOfferwall) return;

    // window?.APRewardOfferwall?.onDisagreePrivacy(false);
    sendMessageToSDK('onDisagreePrivacy', { callback: false }, platform);
  };

  const onClickClose = () => {
    // if (!window) return;
    // if (!window?.APRewardOfferwall) return;

    // window?.APRewardOfferwall?.closeOfferwall();
    if (isNewInappBrowser) {
      return sendMessageToSDK('closeNewInAppBrowser', null, platform);
    }
    sendMessageToSDK('closeOfferwall', null, platform);
  };

  return (
    <div className="flex flex-col gap-[10px] pt-[8px]">
      <div
        role="presentation"
        className="text-gray-500 text-[13px] flex gap-[8px]"
        onClick={(e) => {
          setOption('1');
        }}
      >
        <input type="radio" value="1" checked={option === '1'} readOnly />
        <span>리워드 지급이 완료됐다는데, 리워드가 안 들어왔어요.</span>
      </div>
      <div
        role="presentation"
        className="text-gray-500 text-[13px] flex gap-[8px]"
        onClick={(e) => {
          setOption('2');
        }}
      >
        <input type="radio" value="2" checked={option === '2'} readOnly />
        <span>이미 참여했다는 메시지만 나와요.</span>
      </div>
      {parameter && (
        <div
          role="presentation"
          className="text-gray-500 text-[13px] flex gap-[8px]"
          onClick={(e) => {
            setOption('3');
            if (platform === 'ios') {
              return setShowIosAlert(true);
            }
            setShowAlert(true);
          }}
        >
          <input type="radio" value="3" checked={option === '3'} readOnly />
          <span>개인정보 수집을 해제하고 싶어요.</span>
        </div>
      )}
      <textarea
        name="message"
        disabled
        placeholder={placeholderMsgObject[option]}
        className="h-[120px] placeholder-gray-300 text-[15px] p-[14px] border border-gray-100 rounded-[8px]"
        // onChange={onChangeRequestData}
        // value={formData.message}
      />
      {showAlert && (
        <div className="w-full h-[100vh] bg-[rgba(0,0,0,0.5)] z-20 absolute top-0 left-0 overflow-hidden px-[30px] flex justify-center items-center">
          <div className="w-[300px] rounded-[14px] bg-white p-[16px]">
            <div className="text-gray-900 text-[18px] leading-[27px] flex justify-center">
              개인정보 수집 해제
            </div>
            <div className="pt-[8px] pb-[16px] text-[14px] text-gray-600 leading-[21px] flex flex-col gap-[12px]">
              개인정보 수집을 해제하면 더 이상 애드팝콘 <br /> 서비스를 사용하실 수 없습니다.
              <br />
              <br /> 그래도 진행하시겠습니까?
            </div>
            <div className="flex gap-[10px]">
              <button
                className="flex justify-center items-center w-[50%] h-[46px] rounded-[8px] bg-[#F1F2F4] text-[#9CA6AF]"
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                아니오
              </button>
              <button
                style={{
                  backgroundColor: custom.primaryColor,
                }}
                onClick={onClickDisAgree}
                // disabled={!isChecked}
                className={`flex justify-center items-center w-[50%] h-[46px] rounded-[8px] text-white`}
              >
                예
              </button>
            </div>
          </div>
        </div>
      )}
      {showIosAlert && (
        <div className="w-full h-[100vh] bg-[rgba(0,0,0,0.5)] z-20 absolute top-0 left-0 overflow-hidden px-[30px] flex justify-center items-center">
          <div className="w-[300px] rounded-[14px] bg-white p-[16px]">
            <div className="text-gray-900 text-[18px] leading-[27px] flex justify-center">
              개인정보 수집 해제
            </div>
            <div className="pt-[8px] pb-[16px] text-[14px] text-gray-600 leading-[21px] flex flex-col gap-[12px]">
              개인정보 수집 해제를 원하실 경우 아래의 경로로 설정이 필요합니다. <br />
              <br />
              {`[설정 >
              개인정보 수집 해제 할 APP 선택 > 추적허용 ON설정을 OFF로 변경 or 설정 > 개인정보 보호
              > 추적 > 개인정보 수집 해제할 APP의 ON 설정을 OFF로 변경]`}
            </div>
            <div className="flex gap-[10px]">
              <button
                style={{
                  backgroundColor: custom.primaryColor,
                }}
                onClick={() => setShowIosAlert(false)}
                // disabled={!isChecked}
                className={`flex justify-center items-center w-[100%] h-[46px] rounded-[8px] text-white`}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      )}
      {showAlert2 && (
        <div className="w-full h-[100vh] bg-[rgba(0,0,0,0.5)] z-20 absolute top-0 left-0 overflow-hidden px-[30px] flex justify-center items-center">
          <div className="w-[300px] rounded-[14px] bg-white p-[16px]">
            <div className="text-gray-900 text-[18px] leading-[27px] flex justify-center">
              개인정보 수집 해제
            </div>
            <div className="pt-[8px] pb-[16px] text-[14px] text-gray-600 leading-[21px] flex flex-col gap-[12px]">
              개인정보 수집 해제가 완료 되었습니다.
            </div>
            <button
              style={{
                backgroundColor: custom.primaryColor,
              }}
              className={`w-full flex justify-center items-center h-[46px] rounded-[8px] text-white`}
              onClick={onClickClose}
            >
              확인
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

function CSCampaigns({ csCampaign, disabled }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { openModal } = useErrorModal();
  const custom = useRecoilValue(customState);
  const platform = useRecoilValue(platformState);

  const isTestFromQS = queryParams.get('isTest');
  const isTest = useRecoilValue(isTestState);

  const ISTEST = isTestFromQS ? isTestFromQS === 'true' : isTest;
  const navigate = useNavigate();
  const [showBottomModal, setShowBottomModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const initialState = {
    customer_name: '',
    customer_phone: '',
    customer_email: '',
    message: '',
  };

  const [showModal, setShowModal] = useState(false);

  const CSCampaigns = [
    ...(csCampaign?.CSCampaigns ?? []),
    { Auth: 'ETC', Title: '기타 문의 사항' },
  ];

  return (
    <div className="px-[15px] py-[16px] flex flex-col">
      {showModal && (
        <Modal
          isOpen={showModal?.ResultMsg}
          onClose={() => setShowModal(false)}
          modalContent={{
            ResultMessage: showModal.Result
              ? '문의내용이 정상적으로 접수되었습니다.'
              : showModal?.ResultMsg,
            center: {
              text: '확인',
              func: () => {
                setShowModal(false);
                navigate(-1);
              },
            },
          }}
        />
      )}
      <div
        className="px-[16px] pyw-full h-[48px] border-gray-100 border rounded-[8px] flex items-center justify-between"
        role="presentation"
        onClick={() => setShowBottomModal(true)}
      >
        <div className={`${selectedCampaign ? 'text-gray-900' : 'text-gray-500'}`}>
          {(CSCampaigns.length &&
            CSCampaigns?.find((campaign) => campaign.Auth === selectedCampaign)?.Title) ||
            '문의 할 캠페인 선택'}
        </div>
        <img width={20} height={20} src={arrowDropDown} alt="더보기" />
      </div>
      {selectedCampaign === 'ETC' && <EtcQuestions />}

      {showBottomModal && (
        <div className="w-full h-[100vh] bg-[rgba(0,0,0,0.5)] z-20 absolute top-0 left-0 overflow-hidden">
          <div className="p-[20px] absolute bottom-0 rounded-t-[10px] bg-white z-30 w-full max-h-[calc(100vh-50px)] flex flex-col">
            <div className="text-[18px] pb-[18px] leading-[25.2px] text-gray-900 flex w-full items-center justify-center">
              캠페인 선택
            </div>
            <div className="flex flex-col text-[16px] leading-[22.4px] gap-[16px] h-full overflow-scroll">
              {CSCampaigns?.length &&
                CSCampaigns?.map((campaign, idx) => {
                  const isSelected = campaign.Auth === selectedCampaign;
                  return (
                    <div className="flex justify-between" key={idx}>
                      <div
                        className={`${isSelected ? 'text-gray-900' : 'text-gray-500'}`}
                        role="presentation"
                        onClick={() => {
                          setShowBottomModal(false);
                          if (campaign.Auth !== 'ETC') {
                            const encodedTitle = campaign.Title
                              ? encodeURIComponent(campaign.Title)
                              : '';
                            return sendMessageToSDK(
                              'openWebBrowser',
                              {
                                url:
                                  `https://offerwall.adpopcorn.com/cs-request?auth=${campaign.Auth}&campaign=${encodedTitle}&isTest=${ISTEST}&primaryColor=${custom.primaryColor}` ||
                                  '',
                              },
                              platform
                            );
                          }
                          setSelectedCampaign(campaign.Auth);
                        }}
                      >
                        {campaign.Title}
                      </div>
                      {isSelected && (
                        <Check
                          width={20}
                          height={20}
                          stroke={custom.primaryColor}
                          className="relative right-0"
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CSPage;
